import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '@/auth/auth0-plugin'

import Parties from '@/axios/parties'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRelationships: [],
    adGroups: [],
    snackbar: {}
  },
  getters: {
    adGroups(state) {
      return state.adGroups
    },
    userRelationships(state) {
      return state.userRelationships
    }
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = payload
    },
    setAdGroups(state, payload) {
      state.adGroups = payload
    },
    setUserRelationships(state, payload) {
      state.userRelationships = payload
    }
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.timeout = 3000
      if (snackbar.status === 'error') {
        snackbar.timeout = -1
      }
      snackbar.icon =
        snackbar.status === 'success'
          ? 'mdi-checkbox-marked-circle-outline'
          : 'mdi-alert'
      snackbar.closeIcon = 'mdi-close-thick'
      snackbar.color = snackbar.status === 'success' ? 'success' : 'alert'
      commit('setSnackbar', snackbar)
    },
    async getAdGroups(context) {
      const authService = getInstance()
      if (context.getters.adGroups.length > 0) return
      try {
        const res = await Parties.getAdGroups()
        if (res?.data.length) {
          const adGroups = res.data.filter(result => {
            if (result?.attributes?.AD_GROUP_CODE) {
              if (authService.tenant === 'alliance-retail-group') {
                if (result?.attributes?.AD_GROUP_IS_TPR_GROUP === 'True') return result
              } else {
                return result
              }
            }
          })
          context.commit('setAdGroups', adGroups)
        }
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        context.dispatch('setSnackbar', {
          status: 'error',
          text: `Error retrieving ad groups: ${message}`
        })
      }
    },
    async getUserRelationships(context) {
      const authService = getInstance()
      const { email } = authService.user
      try {
        const relationships = await Parties.getUserProfile(email)
        context.commit(
          'setUserRelationships',
          relationships.data.filter(
            relationship =>
              relationship.party_type_constant === 'AD_GROUP' ||
              relationship.party_type_constant === 'STORE'
          )
        )
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        context.dispatch('setSnackbar', {
          status: 'error',
          text: `Error retrieving user information: ${message}`
        })
      }
    }
  },
  modules: {}
})
