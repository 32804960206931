var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-subtitle-1" },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-pencil")]),
              _vm._v(" Edit TPR Batch Name "),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "grey lighten-3",
                  color: "primary",
                  dense: "",
                  flat: "",
                  "hide-details": "",
                  placeholder: _vm.batch.name,
                  rules: [_vm.rules.required],
                },
                model: {
                  value: _vm.newBatchName,
                  callback: function ($$v) {
                    _vm.newBatchName = $$v
                  },
                  expression: "newBatchName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "green white--text",
                  class: _vm.body,
                  attrs: { loading: _vm.loading, disabled: !_vm.newBatchName },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }