import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Items {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.tprs = base
  }

  async getItems(
    partyId,
    limit = 100,
    offset = 0,
    batchId,
    requestBody,
  ) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_search/${batchId}`,
      method: 'POST',
      data: requestBody,
      params: {
        party_id: partyId,
        limit: limit,
        offset: offset,
        batch_id: batchId,
      }
    })
  }

  async getPromosByRow(batchId, tprId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/rolled_promos',
      method: 'GET',
      params: {
        batch_id: batchId,
        tpr_mgmt_id: tprId,
        limit: 100,
        offset: 0
      }
    })
  }

  async tprUpdate(requestBody) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_report/update',
      method: 'PUT',
      data: requestBody
    })
  }

  async tprDelete(tprId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_report/${tprId}`,
      method: 'DELETE',
    })
  }

  async uploadTprSrp(data, adGroups) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_file/upload`,
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      params: { ad_group_ids: adGroups }
    })
  }
}

export default new Items()
