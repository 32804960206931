export const userAccess = {
  computed: {
    entities() {
      return ['tpr_batch', 'tpr_item']
    },
    tenant() {
      return this.$auth.tenant
    },
    userId() {
      return this.$auth.user.email
    },
    userData() {
      return this.$auth.user
    },
    userGroups() {
      return this.$auth.userGroups || []
    },
    userPermissions() {
      return this.$auth.permissions || []
    },
    userRelationships() {
      return this.$store.getters.userRelationships
    },
    userRelatedPartyIds() {
      if (this.userRelationships.length > 0) {
        return this.userRelationships.map(u => u.id)
      }
      return []
    },
    allAdGroups() {
      return this.$store.getters.adGroups
    },
    limitedAdGroups() {
      return this.allAdGroups.filter(adGroup => {
        if (this.userRelatedPartyIds.includes(adGroup.id)) {
          return adGroup
        }
      })
    },
    userAdGroups() {
      const adGroups = this.adminUser ? this.allAdGroups : this.limitedAdGroups;
      if (adGroups.length > 0) {
        return adGroups.slice().sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return []
      }
    },

    hasPortalAccess() {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      return readScopes.every(scope => this.userPermissions.includes(scope))
    },
    adminUser() {
      return (
        this.userGroups.includes('tpr-manager.admin') ||
        this.adminPermissions.every(scope =>
          this.userPermissions.includes(scope)
        )
      )
    },
    adminPermissions() {
      return this.$auth.buildScopes(this.entities)
    },
    hasRetailWriteAccess() {
      const scopes = ['tpr-manager:update']
      return scopes.every(scope => this.userPermissions.includes(scope))
    },
    limitAccessByRelatedStores() {
      return this.userPermissions.includes('retail:related_stores')
    }
  }
}
