import Vue from "vue"
import VueRouter from "vue-router"
import BatchList from "../views/BatchList.vue"
import BatchView from "../views/BatchView.vue"
import { authGuard } from "@/auth/authGuard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "BatchList",
    component: BatchList,
    meta: {
      title: "Batches",
      requiresAuth: true
    }
  },
  {
    path: "/view-batch",
    name: "BatchView",
    component: BatchView,
    meta: {
      title: "View Batch",
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  to.meta.referrer = from.name
  // Use the route's "meta.title" value to assign the page's title
  if (to.meta.title) document.title = to.meta.title
  // For routes requiring authentication
  if (to.meta.requiresAuth === true) {
    return authGuard(to, from, next)
  }
  return next()
})

export default router
