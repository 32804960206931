import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Batches {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.tprs = base
  }

  async getBatches(
    adGroupId,
    startDt,
    endDt,
    limit = 100,
    offset = 0,
    requestBody
  ) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch_search',
      method: 'POST',
      data: requestBody,
      params: {
        ad_grp_id: adGroupId,
        start_dt: startDt,
        end_dt: endDt,
        limit: limit,
        offset: offset
      }
    })
  }

  async versionCheck(adGroupId, tprDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/check_tpr_version/${adGroupId}`,
      method: 'GET',
      params: {
        tpr_date: tprDate
      }
    })
  }

  async batchCreate(adGroupId, tprDate, batchName, tprEndDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch',
      method: 'POST',
      params: {
        ad_group_id: adGroupId,
        tpr_date: tprDate,
        tpr_end_date: tprEndDate,
        batch_name: batchName,
      }
    })
  }

  async batchUpdate(adGroupId, tprDate, batchId, tprEndDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch',
      method: 'POST',
      params: {
        ad_group_id: adGroupId,
        tpr_date: tprDate,
        batch_id: batchId,
        tpr_end_date: tprEndDate
      }
    })
  }

  async batchPublish(batchId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/publish_tpr_report/${batchId}`,
      method: 'POST'
    })
  }

  async exportBatch(batchId, batchName, adGroupName, tprDate, tprEndDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/export/${batchId}`,
      method: 'GET',
      params: {
        batch_name: batchName,
        ad_group_name: adGroupName,
        tpr_date: tprDate,
        tpr_end_date: tprEndDate
      }
    })
  }

  async updateBatchName(batchId, batchName){
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_batch/${batchId}`,
      method: 'PUT',
      params: {
        batch_name: batchName,
        batch_id: batchId
      }
    })
  }
}

export default new Batches()
