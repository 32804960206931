<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      :expanded="expanded"
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items="items"
      :loading="loading"
      :search="search"
      :item-class="checkPromoPrice"
      loading-text="Loading Items...Please Wait"
      loader-color="orange"
      dense
      fixed-header
      hide-default-footer
      item-key="id"
      show-select
      sort-by="[id, linked_group]"
      v-model="selected">
      <template #top>
        <v-toolbar flat color="toolbar">
          <v-row dense align="center">
            <v-breadcrumbs large :items="breadcrumbs">
              <template #item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  <span :class="!item.disabled ? 'primary--text' : ''">{{
                    item.text
                  }}</span>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-spacer />
            <v-menu
              v-if="isFiltered"
              bottom
              offset-y
              open-on-hover
              max-height="300">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="orange white--text ml-2">
                  <v-icon color="white" class="mr-2" small>
                    mdi-filter-outline
                  </v-icon>
                  <span class="text-capitalize">
                    Results for {{ searchValues.length }} search values
                  </span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="searchValues = []"
                        class="white--text ml-2"
                        icon
                        x-small>
                        <v-icon small>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Clear Filter</span>
                  </v-tooltip>
                </v-chip>
              </template>
              <v-card>
                <v-card-title class="text-subtitle-2 pb-0">
                  Search Values:
                </v-card-title>
                <v-list>
                  <v-list-item
                    v-for="(value, index) in searchValues"
                    :key="index">
                    {{ value }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-spacer />
            <v-menu
              :close-on-content-click="false"
              :nudge-bottom="3"
              :nudge-left="70"
              :value="srpMenu"
              bottom
              offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      :disabled="!selected.length"
                      small
                      :readonly="!selected.length"
                      @click="srpMenu = true"
                      class="grey lighten-2"
                      height="38"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
              <v-card class="fill" width="200">
                <v-card-title class="pb-0 text-subtitle-1"> Edit </v-card-title>
                <v-form class="px-4 pt-4" ref="form">
                  <v-text-field
                    :rules="multiplierRules"
                    background-color="input"
                    dense
                    label="Multiplier"
                    outlined
                    type="number"
                    v-model="multiplier">
                  </v-text-field>
                  <v-text-field
                    :rules="srpRules"
                    background-color="input"
                    dense
                    label="SRP"
                    outlined
                    placeholder="0.00"
                    prefix="$"
                    type="number"
                    v-model="srp">
                  </v-text-field>
                </v-form>
                <v-card-actions class="fill">
                  <v-spacer />
                  <v-btn
                    :class="body"
                    @click=";(srp = ''), (srpMenu = false)"
                    text>
                    Cancel
                  </v-btn>
                  <v-btn
                    :class="body"
                    @click="bulkUpdate"
                    class="green white--text mr-2">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              :nudge-bottom="3"
              :nudge-left="70"
              :value="deleteMenu"
              bottom
              offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      :disabled="!selected.length"
                      small
                      :readonly="!selected.length"
                      @click="deleteMenu = true"
                      class="grey lighten-2 ml-2"
                      height="38"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <v-card class="fill" width="200">
                <v-card-text align="center" class="black--text text-subtitle-1">
                  Delete {{ selected.length }} items?
                </v-card-text>
                <v-card-actions class="fill">
                  <v-spacer />
                  <v-btn :class="body" @click="deleteMenu = false" text>
                    Cancel
                  </v-btn>
                  <v-btn
                    :class="body"
                    @click="bulkDelete"
                    class="red white--text mr-2">
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <ItemSearch
              @search="searchValues = $event"
              @clear="getItems(), (isFiltered = false)">
            </ItemSearch>
            <v-menu
              bottom
              offset-y
              :nudge-left="25"
              :nudge-bottom="3"
              :close-on-content-click="false"
              :value="actionMenu">
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      :disabled = "isSyncing"
                      small
                      class="grey lighten-2 mx-2"
                      height="38"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      @click="actionMenu = true">
                      <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Batch Actions</span>
                </v-tooltip>
              </template>
              <v-card class="fill">
                <v-list class="fill">
                  <v-list-item
                    v-for="(action, index) in actions"
                    :key="index"
                    link
                    @click="action.method(selectedBatch)">
                    <v-list-item-icon class="mr-4">
                      <v-icon>{{ action.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ action.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-col md="4" lg="3" xl="2">
              <v-text-field
                background-color="input"
                class="mt-7"
                dense
                label="Filter Items"
                outlined
                prepend-inner-icon="mdi-filter-outline"
                v-model="search" />
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider />
      </template>
      <template #loading>
        <v-container class="ml-0"> Loading Items...Please Wait </v-container>
      </template>
      <template #[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox :ripple="false" v-on="on" v-bind="props" dark />
      </template>
      <template #[`item.enforce_compliance`]="{ item }">
        <v-tooltip :disabled="!item.enforce_compliance" top>
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              :color="item.enforce_compliance ? 'green' : 'grey lighten-1'">
              mdi-shield-check-outline
            </v-icon>
          </template>
          <span>Compliance Enforced</span>
        </v-tooltip>
      </template>
      <template #[`item.upc`]="{ item }">
        <td class="noWrap">{{ item.upc > 2 ? item.upc : '' }}</td>
      </template>
      <template #[`item.cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.cost) }}</td>
      </template>
      <template #[`item.amap_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.amap_allowance) }}
        </td>
      </template>
      <template #[`item.early_buy_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.early_buy_allowance) }}
        </td>
      </template>
      <template #[`item.tpr_billback_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.tpr_billback_allowance) }}
        </td>
      </template>
      <template #[`item.tpr_scan_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.tpr_scan_allowance) }}
        </td>
      </template>
      <template #[`item.edlc_billback_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.edlc_billback_allowance) }}
        </td>
      </template>
      <template #[`item.edlc_scan_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.edlc_scan_allowance) }}
        </td>
      </template>
      <template #[`item.unit_cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.unit_cost) }}</td>
      </template>
      <template #[`item.net_unit_cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.net_unit_cost) }}</td>
      </template>
      <template #[`item.gross_profit_percent`]="{ item }">
        <td class="noWrap">{{ item.gross_profit_percent | format }}%</td>
      </template>
      <template #[`item.retail_price`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.retail_price) }}</td>
      </template>
      <template #[`item.start_dt`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.start_dt) }}</td>
      </template>
      <template #[`item.end_dt`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.end_dt) }}</td>
      </template>
      <template #[`item.original_promotion_price`]="{ item }">
        <td class="noWrap" v-if="item.original_promotion_price">
          ${{ formatCurrencyDisplay(item.original_promotion_price) }}
        </td>
      </template>
      <template #[`item.promotion_price`]="{ item }">
        <td>
          <v-row class="my-1 px-1 rounded">
            <v-col cols="4" class="pa-0">
              <v-text-field
                @change="multiplierUpdate(item, $event)"
                dense
                outlined
                hide-details
                solo
                type="number"
                :value="item.multiplier">
              </v-text-field>
            </v-col>
            <v-icon size="20" class="mx-2">mdi-at</v-icon>
            <v-col class="pa-0">
              <v-text-field
                :readonly="selected.length > 0"
                :rules="srpRules"
                :value="formatCurrencyDisplay(item.promotion_price)"
                @change="srpUpdate(item, $event)"
                prefix="$"
                background-color="input"
                class="px-0"
                dense
                outlined
                hide-details
                solo
                step=".01"
                type="number">
              </v-text-field>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #[`item.promo_info`]="{ item }">
        <v-menu
          :close-on-content-click="false"
          offset-x
          offset-y
          open-on-click
          right>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  @click="getPromosByRow(item)"
                  color="primary"
                  icon
                  tabindex="-1"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>View Promos</span>
            </v-tooltip>
          </template>
          <v-card width="400">
            <v-toolbar flat class="accent" dark>
              <v-toolbar-title>Included in Allowances</v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-container
              fluid
              class="ma-0 pa-0"
              style="max-height: 250px; scroll-y: auto">
              <v-list class="py-0" dense>
                <v-list-item
                  v-for="promo in promosByRow"
                  :key="promo.promo_id"
                  link
                  @click="openLink(promo.promo_id)">
                  <v-list-item-content class="primary--text">
                    <v-list-item-title>{{
                      promo.promo_name
                    }}</v-list-item-title>
                    <v-list-item-subtitle v-if="promosByRow.length > 0">
                      {{ promo.promo_number }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      No Promos to Show
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card>
        </v-menu>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="grey lighten-3 pa-0">
          <v-text-field
            tabindex="-1"
            background-color="grey lighten-3"
            color="primary"
            dense
            flat
            hide-details
            placeholder="Add Note"
            v-model="item.note"
            @change="noteUpdate(item)">
            <template #prepend-inner>
              <label class="font-weight-medium mx-3 pt-1">Note:</label>
            </template>
          </v-text-field>
        </td>
      </template>
      <template #footer>
        <v-divider></v-divider>
        <v-toolbar flat color="#fff" style="margin-top: 8px">
          <v-row align="center" style="padding-bottom: 3px">
            <v-spacer />
            <v-col cols="2" style="max-width: 150px">
              <v-select
                :items="pageSizes"
                :menu-props="{
                  top: true,
                  offsetY: true,
                  maxHeight: 500
                }"
                class="mt-7"
                dense
                label="Items Per Page"
                v-model="pageSize">
              </v-select>
            </v-col>
            <v-btn
              :disabled="disablePrevious"
              @click="previous"
              class="mx-2"
              icon
              large>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn
              :disabled="disableNext"
              @click="next"
              class="mx-2"
              icon
              large>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <AlertDialog
      :value.sync="alertDialog"
      :batch="selectedBatch"
      :total="total"
      @update="batchUpdate($event)" />
    <v-overlay absolute :value="exporting">
      <v-progress-circular indeterminate size="100">
        Exporting...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Items from '@/axios/items'
import ItemSearch from '@/components/ItemSearch'
import { fullwidth } from '@/mixins/fullwidth'
import { paging } from '@/mixins/paging'
import { displayHelpers } from '@/mixins/displayHelpers'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { shared } from '@/mixins/shared'

export default {
  name: 'BatchView',
  components: { ItemSearch },
  mixins: [fullwidth, displayHelpers, paging, userAccess, utils, shared],
  data() {
    return {
      actionMenu: false,
      selectedAdGroup: {},
      dateRange: [],
      deleteMenu: false,
      expanded: [],
      filtered: false,
      items: [],
      loading: true,
      multiplier: '',
      noteMenu: false,
      search: '',
      searchMenu: false,
      searchValues: [],
      selectedRow: {},
      selected: [],
      srp: '',
      srpMenu: false,
      tprDate: '',
      tprEndDate: ''
    }
  },
  computed: {
    promosByRow() {
      return this.selectedRow ? this.selectedRow.promos : []
    },
    isFiltered() {
      return this.searchValues.length > 0 ? this.searchValues.length > 0 : false
    },
    breadcrumbs() {
      const tprDateFormatted = this.moment(this.tprDate).format(
        this.$dateConfig.date_display
      )
      return [
        {
          text: this.selectedAdGroup.name,
          exact: true,
          href: '/'
        },
        {
          text: this.selectedBatch?.batch_name
            ? `${this.selectedBatch.batch_name} (${tprDateFormatted})`
            : tprDateFormatted,
          exact: true,
          disabled: true
        }
      ]
    },
    headers() {
      return [
        { sortable: false, class: 'accent', value: 'data-table-select' },
        {
          text: 'Department',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'department'
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'category'
        },
        {
          text: 'Linked Group',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'linked_group'
        },
        {
          text: 'UPC',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'upc'
        },
        {
          text: 'Item ID',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'item_id'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'description'
        },
        {
          text: 'Pack',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'qty_per_case'
        },
        {
          text: 'Size',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'pack_size'
        },
        {
          text: 'Case Cost',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'cost'
        },
        {
          text: 'Reg Unit Cost',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'unit_cost'
        },
        {
          text: 'AMAP',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'amap_allowance'
        },
        {
          text: 'EBA',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'early_buy_allowance'
        },
        {
          text: 'TPR Billback',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'tpr_billback_allowance'
        },
        {
          text: 'TPR Scan',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'tpr_scan_allowance'
        },
        {
          text: 'EDLC Billback',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'edlc_billback_allowance'
        },
        {
          text: 'EDLC Scan',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'edlc_scan_allowance'
        },
        {
          text: 'Net Unit Cost',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'net_unit_cost'
        },
        {
          text: 'Compliance Enforced',
          align: 'center',
          sortable: true,
          class: 'accent white--text',
          value: 'enforce_compliance'
        },
        {
          text: 'Multiplier @ SRP',
          align: 'center',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'promotion_price',
          width: '250'
        },
        {
          text: 'GP%',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'gross_profit_percent'
        },
        {
          text: 'Retail Price',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'retail_price'
        },
        {
          text: 'Start Date',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'start_dt'
        },
        {
          text: 'End Date',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'end_dt'
        },
        {
          align: 'start',
          sortable: false,
          filterable: false,
          class: 'accent white--text',
          value: 'promo_info'
        }
      ]
    },
    disablePrevious() {
      return (this.page - 1) * this.pageSize === 0
    },
    disableNext() {
      return this.items.length < this.pageSize
    },
    srpRules() {
      const rules = []

      const valueRequired = v => !!v || 'SRP Required'
      rules.push(valueRequired)

      const positiveValue = v => parseFloat(v) >= 0 || 'Invalid SRP'
      rules.push(positiveValue)

      return rules
    },
    multiplierRules() {
      const rules = []

      const valueRequired = v => !!v || 'Multiplier Required'
      rules.push(valueRequired)

      const positiveValue = v => v > 0 || 'Invalid Multiplier'
      rules.push(positiveValue)

      return rules
    }
  },
  watch: {
    srpMenu: {
      handler(newValue) {
        if (newValue === false) {
          this.$refs.form.reset()
        }
      }
    },
    searchMenu: {
      handler(newValue) {
        if (newValue === false) {
          this.$refs.form.reset()
        }
      }
    },
    searchValues: {
      handler(newValue, oldValue) {
        sessionStorage.setItem(
          'tpr_manager_item_search',
          JSON.stringify(newValue)
        )
        if (newValue.length !== oldValue.length) {
          this.getItems()
        }
      }
    }
  },
  created() {
    this.nonTableHeight = 240
    const { ad_group, tpr_date, tpr_end_date, selected_batch } = this.$route.params
    const {
      tpr_manager_ad_group,
      tpr_manager_tpr_date,
      tpr_manager_tpr_end_date,
      tpr_manager_selected_batch,
      tpr_manager_item_search
    } = sessionStorage

    this.selectedAdGroup = ad_group || JSON.parse(tpr_manager_ad_group)
    this.tprDate = tpr_date || tpr_manager_tpr_date
    this.tprEndDate = tpr_end_date || tpr_manager_tpr_end_date
    this.selectedBatch =
      selected_batch || JSON.parse(tpr_manager_selected_batch)
    this.searchValues = tpr_manager_item_search
      ? JSON.parse(tpr_manager_item_search)
      : []

    this.getItems()
    this.tableChanged = this.tableChange(this.getItems)
  },
  methods: {
    async getPromosByRow(item) {
      const { id } = item
      const tprId = id
      try {
        const { data } = await Items.getPromosByRow(
          this.selectedBatch.id,
          tprId
        )
        this.selectedRow = data
      } catch (err) {
        console.log(err)
      }
    },
    openLink(promoId) {
      let baseUrl = process.env.VUE_APP_PROMO_PORTAL
      let prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        baseUrl = `${prefix}.${baseUrl}`
      }
      const url = `https://${baseUrl}/promo?id=${promoId}`
      window.open(url)
    },
    checkPromoPrice(item) {
      return item.gross_profit_percent === null ? 'red white--text' : ''
    },
    async getItems() {
      try {
        const { data } = await Items.getItems(
          this.selectedAdGroup.id,
          this.pageSize,
          this.from,
          this.selectedBatch.id,
          this.isFiltered ? this.searchValues : [],
        )
        this.items = data
        this.expanded = data
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`No Matching Items Found: ${message}`)
      } finally {
        this.loading = false
      }
    },
    async noteUpdate(item) {
      const payload = [
        {
          id: item.id,
          promotion_price: item.promotion_price,
          multiplier: item.multiplier,
          note: item.note
        }
      ]
      try {
        await Items.tprUpdate(payload)
        this.notify('Note updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating note: ${message}`)
      } finally {
        await this.getItems()
        this.loading = false
      }
    },
    async srpUpdate(item, srp) {
      this.loading = true
      let payload = []
      if (this.selected.length) {
        this.selected.forEach(item => (item.promotion_price = this.srp))
      } else {
        payload = [
          {
            id: item.id,
            promotion_price: srp,
            multiplier: item.multiplier,
            note: item.note
          }
        ]
      }
      try {
        await Items.tprUpdate(payload)
        this.selected = []
        this.srp = ''
        this.srpMenu = false
        this.notify('SRP updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating SRP: ${message}`)
      } finally {
        await this.getItems()
        this.loading = false
      }
    },
    async multiplierUpdate(item, multiplier) {
      const payload = [
        {
          id: item.id,
          promotion_price: item.promotion_price,
          multiplier: multiplier,
          note: item.note
        }
      ]
      try {
        await Items.tprUpdate(payload)
        this.notify('Multiplier updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating multiplier: ${message}`)
      } finally {
        await this.getItems()
        this.loading = false
      }
    },
    async bulkUpdate() {
      this.loading = true
      const payload = this.selected.map(item => {
        return {
          id: item.id,
          promotion_price: this.srp ? this.srp : item.promotion_price,
          multiplier: this.multiplier ? this.multiplier : item.multiplier,
          note: item.note
        }
      })
      try {
        await Items.tprUpdate(payload)
        this.selected = []
        this.srp = ''
        this.srpMenu = false
        this.notify('TPR updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating TPR: ${message}`)
      } finally {
        await this.getItems()
        this.loading = false
      }
    },
    async bulkDelete() {
      this.loading = true
      try {
        await Promise.allSettled(
          this.selected.map(item => Items.tprDelete(item.id))
        )
        this.deleteMenu = false
        this.selected = []
        this.notify('TPRs deleted successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error deleting TPRs: ${message}`)
      } finally {
        this.getItems()
      }
    }
  }
}
</script>
