var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-bottom": 3,
        "nudge-left": 75,
        value: _vm.uploadMenu,
        bottom: "",
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var menu = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var tooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "grey lighten-2 ml-2",
                                    attrs: { small: "", height: "38" },
                                    on: {
                                      click: function ($event) {
                                        _vm.uploadMenu = true
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                Object.assign({}, tooltip, menu)
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-folder-upload-outline"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("TPR SRP Upload")])]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticClass: "fill",
          attrs: { "min-width": "450", "max-width": "700" },
        },
        [
          _c("v-card-title", { staticClass: "pb-0 text-subtitle-1" }, [
            _vm._v(" New TPR SRP Upload "),
          ]),
          _c(
            "v-container",
            { staticClass: "pt-0 pb-2 px-6" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-file-input", {
                    staticClass: "mt-4",
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      "prepend-inner-icon": "mdi-paperclip",
                      "prepend-icon": "",
                      accept: ".csv",
                      chips: "",
                      label: "Select File for Upload",
                      "truncate-length": "25",
                      "show-size": "",
                      rules: [
                        function (v) {
                          return !!v || "File is Required"
                        },
                      ],
                    },
                    on: { change: _vm.handleFile },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "white",
                      label: "Base Ad Group",
                      items: _vm.adGroups,
                      "item-text": "name",
                      "return-object": "",
                      "menu-props": { maxHeight: 215 },
                      rules: [
                        function (v) {
                          return !!v || "Base Ad Group is Required"
                        },
                      ],
                    },
                    on: { change: _vm.changeBaseGroup },
                    model: {
                      value: _vm.baseGroup,
                      callback: function ($$v) {
                        _vm.baseGroup = $$v
                      },
                      expression: "baseGroup",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pt-0 pr-0 pl-4", attrs: { fluid: "" } },
            [
              _c(
                "v-list",
                {
                  staticClass: "pt-0",
                  staticStyle: { height: "300px", overflow: "auto" },
                  attrs: { color: "white", loading: _vm.loading },
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { "active-class": "primary--text", multiple: "" },
                      model: {
                        value: _vm.selectedAdGroups,
                        callback: function ($$v) {
                          _vm.selectedAdGroups = $$v
                        },
                        expression: "selectedAdGroups",
                      },
                    },
                    [
                      _vm._l(_vm.dcMatchedAdGroups, function (item, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: item.id,
                              attrs: { value: item.id, dense: "" },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", { attrs: { color: "green" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedAdGroups.includes(item.id)
                                          ? "mdi-check"
                                          : ""
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          index < _vm.dcMatchedAdGroups.length - 1
                            ? _c("v-divider", { key: index })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "fill" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.uploadMenu = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "green white--text",
                  class: _vm.body,
                  attrs: {
                    loading: _vm.loading,
                    disabled: !_vm.hasRequiredFields,
                    color: "success",
                  },
                  on: { click: _vm.uploadFile },
                },
                [
                  _vm._v(" Upload "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-file-upload-outline"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }