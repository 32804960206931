import axios from "axios"
import AuthToken from "@/auth/auth-token"

class Parties {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        "Content-type": "application/json"
      }
    })
    this.parties = base
  }

  async getUserProfile(email) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: "/user_profile",
      method: "GET",
      params: {
        email: email
      }
    })
  }

  async getCustomerID(partyEmail, partyType) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: "/tomer_id",
      method: "GET",
      params: {
        party_email: partyEmail,
        party_type: partyType
      }
    })
  }

  async getUpstreamRelationships(partyID) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: "/party_relationship/upstream",
      method: "GET",
      params: {
        party_id: partyID
      }
    })
  }

  async getParties(id) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: `/party/${id}`,
      method: "GET"
    })
  }

  async getAdGroups (offset = 0, limit = 250) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: '/party/search_parties',
      method: 'POST',
      data: { party_types: ['AD_GROUP']},
      params: { offset, limit }

    })
  }

  async upstreamRelationshipSearch (payload) {
    await AuthToken.setAuth(this.parties, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.parties({
      url: '/party_relationship/upstream_search',
      method: 'POST',
      data: payload
    })
  }
}

export default new Parties()
