<template>
  <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>
  <v-app v-else id="inspire">
    <v-app-bar app color="main" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mx-auto" />
        <router-link :to="{ path: '/'}">
          <v-img v-if="tenantsWithLogos.includes(`${$auth.tenant}`)"
            max-height="110"  
            max-width="110"
            class="ml-6"
            :src="require(`@/assets/${$auth.tenant}_logo.png`)" 
            :alt="`${$auth.tenant} Logo`"> 
          </v-img>
          <v-app-bar-nav-icon v-else>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
        </router-link>
      <v-spacer />
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="tpr" />
      </v-col>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav>
        <v-list-item :to="{ path: '/' }" exact link>
          <v-list-item-action>
            <v-icon>mdi-note-text-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>TPR Manager</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[!miniVariant ? 'pa-2' : 'pa-1']">
          <v-btn
            block
            id="logoutBtn"
            :style="styleObject"
            @click.prevent="logout()"
          >
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <!-- <AlertBar></AlertBar> -->
      <router-view v-if="hasPortalAccess" />
      <v-alert v-else type="error" text border="left">
        You don't have authorized permissions to access this site.
      </v-alert>
      <v-hover v-slot="{ hover }">
        <v-snackbar
          top
          right
          rounded="md"
          height="50"
          content-class="body-1"
          transition="slide-y-transition"
          v-model="snackbar.showing"
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-btn v-if="snackbar.status === 'error'" icon @click="closeSnackbar">
            <v-icon v-if="!hover">{{ snackbar.icon }}</v-icon>
            <v-icon v-else>{{ snackbar.closeIcon }}</v-icon>
          </v-btn>
          <v-icon v-else left>
            {{ snackbar.icon }}
          </v-icon>
          {{ snackbar.text }}
        </v-snackbar>
      </v-hover>
    </v-main>
    <v-footer color="main" app class="justify-space-between">
      <span class="white--text">
        iPro
        <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy;
        {{ new Date().getFullYear() }}
      </span>
      <span class="blue--text text--lighten-2">v. {{ version }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import PortalNavMenu from "@/components/PortalNavMenu";
import { userAccess } from "@/mixins/user-access";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { PortalNavMenu },
  mixins: [userAccess],
  data() {
    return {
      drawer: false,
      miniVariant: false,
      styleObject: {
        background: "#E53935",
        color: "white",
        fontSize: "15px",
      },
    };
  },
  computed: {
    ...mapState(["snackbar"]),
    version() {
      return process.env.VUE_APP_VERSION;
    },
    tenantsWithLogos () {
      return [        
        'awg',
        'alliance-retail-group'      
      ]  
    }
  },
  watch: {
    tenant: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch("getAdGroups");
          this.$store.dispatch("getUserRelationships");
        }
      },
    },
  },
  created() {
    if (this.$auth?.tenant) {
      this.$store.dispatch("getUserRelationships");
    }
  },
  methods: {
    logout() {
      sessionStorage.removeItem("tpr_manager_ad_group");
      sessionStorage.removeItem("tpr_manager_selected_batch");
      sessionStorage.removeItem("tpr_manager_tpr_date");
      sessionStorage.removeItem("tpr_manager_item_search");
      this.$auth.logout({
        returnTo: `${window.location.origin}`,
      });
    },
    closeSnackbar () {
      this.snackbar.showing = false
    }
  },
};
</script>
<style scoped>
span.registeredTrademark {
  position: relative;
  top: -0.3rem;
  font-size: 0.5rem;
  left: -0.05rem;
}
</style>
