export const paging = {
  data() {
    return {
      from: 0,
      page: 1,
      pageSize: 100,
      pageSizes: [100, 250, 500]
    }
  },
  watch: {
    // search: {
    //   handler() {
    //     this.page = 1
    //     this.tableChanged()
    //   }
    // },
    pageSize: {
      handler() {
        if (this.page > 1) {
          return (this.page = 1)
        }
        this.tableChanged()
      }
    },
    page: {
      handler(newValue) {
        if (newValue > 1) {
          this.from = newValue * this.pageSize - this.pageSize
          this.tableChanged()
        } else {
          this.from = 0
          this.tableChanged()
        }
      }
    }
  },
  methods: {
    previous() {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
    },
    next() {
      this.page = this.page + 1
    },
    tableChange(searchFunc) {
      return function () {
        searchFunc()
      }
    },
    submitSearch(searchFunc) {
      return function () {
        searchFunc()
      }
    }
  }
}
